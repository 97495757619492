import { ReactNode, useCallback, useState } from 'react';
import FlushContext from "./FlushContext";
import { FlushScreenType, CheckData, FlushEvent } from "../../components/types/flushTypes";

export const FlushProvider = ({ children }: { children: ReactNode }) => {
    const [currentScreen, setCurrentScreen] = useState<FlushScreenType>('connect');
    const [secondsLeft, setSecondsLeft] = useState<number>(0);
    const [initialSeconds, setInitialSeconds] = useState(0);
    const [checkData, setCheckData] = useState<CheckData | null>(
        null
        // {
        //     publicKey: "JD9YMkyD3XkiQYKBiZd6pDpFyM7osrgJdq8g2CDgKyqX",
        //     flush: null,
        //     // flush: {
        //     //     amount: 52001.21,
        //     //     mint: "B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B",
        //     //     symbol: "PEPE",
        //     //     imageUrl: "https://gateway.pinata.cloud/ipfs/QmZMTnCNYincJTTtNvxptHGEnB36F336C544Re5Zjo2QLj",
        //     //     name: "Pepe",
        //     //     distributed: false
        //     // },
        //     nonce: "234",
        //     endsInt: 340,
        // }
    );

    const [flushEvent, setFlushEvent] = useState<FlushEvent | null>(
        // {
        //     amount: 52801.21,
        //     mint: "B5WTLaRwaUQpKk7ir1wniNB6m5o8GgMrimhKMYan2R6B",
        //     symbol: "PEPE",
        //     imageUrl: "https://gateway.pinata.cloud/ipfs/QmZMTnCNYincJTTtNvxptHGEnB36F336C544Re5Zjo2QLj",
        //     name: "Pepe",
        //     distributed: false
        // }
        null
    );

    const handleInitialFlushNavigation = useCallback(() => {
        if (checkData) {
            if (checkData.flush) {
                setFlushEvent(checkData.flush);
                if (checkData.flush.amount) {
                    setCurrentScreen("winning");
                } else {
                    setCurrentScreen("lost");
                }
            } else {
                if (checkData.nonce && checkData.endsInt) {
                    setCurrentScreen("flush");
                } else {
                    setCurrentScreen("noEvent");
                }
            }
        }
    }, [checkData]);

    return (
        <FlushContext.Provider
            value={{
                currentScreen, setCurrentScreen,
                checkData, setCheckData,
                flushEvent, setFlushEvent,
                secondsLeft, setSecondsLeft,
                initialSeconds, setInitialSeconds,
                handleInitialFlushNavigation,
            }}>
            {children}
        </FlushContext.Provider>
    );
};
