import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useFlushContext } from '../../contexts/flushContext/FlushContext';
import RollingNumber from '../RollingNumber';
import PrimaryButton from '../ui/PrimaryButton';

const WinningScreen = () => {
  const { flushEvent } = useFlushContext();
  const { width, height } = useWindowSize();
  const posthog = usePostHog();
  const [isTweetPosted, setIsTweetPosted] = useState(false);
  const navigate = useNavigate();

  const shareOnXHandler = () => {
    setIsTweetPosted(true);
    posthog?.capture('flush-share', { amount: flushEvent?.amount });
    if (flushEvent?.amount) {
      setTimeout(() => {
        let tweetText = encodeURIComponent(
          `@clapcat THE REAL SISTER OF $POPCAT Won ${flushEvent?.amount} of $CLAP on Flush! @swipedotfun`
        );

        // console.log('t', tweetText);

        if (flushEvent?.shareLink !== null && flushEvent?.shareLink !== undefined) {
          tweetText = tweetText + ' ' + encodeURIComponent(flushEvent?.shareLink);
        }

        // const xAppUrl = `twitter://post?message=${tweetText}`;
        const fallbackUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

        // const startTime = new Date().getTime();

        const win = window.open(fallbackUrl);

        if (win) {
          win?.focus();
        }

        // setTimeout(() => {
        //   const elapsedTime = new Date().getTime() - startTime;
        //   if (elapsedTime < 2000) {
        //     window.location.href = fallbackUrl;
        //   }
        // }, 2000);
      }, 1500);
    }
  };

  const closeHandler = () => {
    posthog?.capture('flush-to-main', { amount: flushEvent?.amount, from: 'win-screen' });
    navigate('/main');
  };

  return (
    <>
      <div className="relative h-screen w-full flex flex-col items-center justify-end">
        <div className="relative flex flex-col items-center justify-center h-full w-full mt-40">
          <div className="relative z-10 flex flex-col items-center justify-center w-full">
            {/* <Emoji name="four-leaf-clover" width={54} className="relative winning-screen-show-up" /> */}

            <div className="h1-wrapper w-full text-center">
              <h1
                data-text="YOU WON"
                className="winning-screen-h1 winning-screen-text font-sf-black">
                YOU WON
              </h1>
            </div>

            <div className="winning-screen-details winning-screen-show-up [@media(max-height:800px)]:mb-[50px]">
              <div className="relative flex flex-col items-center justify-center">
                <img
                  src={flushEvent?.imageUrl}
                  alt="Token image"
                  className="rounded-[50%] object-cover border-2 border-[#FAEE71] block z-10 w-[200px] h-md:w-[256px]"
                />
                <div className="z-20 absolute text-center w-full flex flex-col items-center top-[170px] h-md:top-[210px]">
                  <RollingNumber
                    value={flushEvent?.amount as number}
                    duration={0.5}
                    className="winning-screen-text text-5xl h-md:text-7xl font-sf-black"
                  />
                  <p
                    data-text={flushEvent?.name}
                    className="winning-screen-text absolute text-3xl h-md:text-5xl font-sf-black mt-[45px] h-md:mt-[70px]">
                    {flushEvent?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="winning-screen-show-up-actions text-center flex flex-col items-center h-sm:gap-2 gap-3 w-full px-3 mb-28 h-sm:mb-6 lg:mb-10 mb-[23vw]">
          <p className="font-sf-bold text-base h-md:text-xl opacity-70 mb-[20px]">
            Your tokens will be rewarded to <br /> your wallet shortly
          </p>
          {!isTweetPosted && (
            <PrimaryButton
              classname="font-sf-bold"
              onClick={shareOnXHandler}
              children={<>Speed up claim</>}
              fullWidth
            />
          )}
          {isTweetPosted && (
            <p className="font-sf-semibold text-lg opacity-40">
              Verifying, distribution will be until end of the day
            </p>
          )}
          <button
            className="btn-secondary border border-[#2c2e30] font-sf-bold w-full rounded-2xl bg-[#161616]"
            onClick={closeHandler}>
            Back to Swipe
          </button>
        </div>
      </div>
      <Confetti className="z-[-1]" width={width} height={height} opacity={0.7} />
    </>
  );
};

export default WinningScreen;
