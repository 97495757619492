import React from 'react';

interface PrimaryButtonProps {
  text?: string;
  callback?: () => void;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
  classname?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  callback,
  fullWidth,
  onClick,
  children,
  isDisabled = false,
  classname = ""
}) => {
  const buttonStyle = `btn-primary font-sf-bold ${(fullWidth ? 'w-full' : 'w-32 max-w-64')} ${classname}`;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // Vibrate for 50ms
    }
    if (onClick) {
      onClick(e);
    } else if (callback) {
      callback();
    }
  };

  return (
    <button disabled={isDisabled} className={buttonStyle} onClick={handleClick}>
      {children || text}
    </button>
  );
};

export default PrimaryButton;
