import ConnectScreen from "../components/flush/ConnectScreen";
import FlushingScreen from "../components/flush/FlushingScreen";
import FlushScreen from "../components/flush/FlushScreen";
import LostScreen from "../components/flush/LostScreen";
import NoEventScreen from "../components/flush/NoEventScreen";
import WinningScreen from "../components/flush/WinningScreen";
import { useFlushContext } from "../contexts/flushContext/FlushContext";

const FlushPage = () => {
  const { currentScreen } = useFlushContext();
  if (currentScreen === "flush") {
    return <FlushScreen />
  } else if (currentScreen === "flushing") {
    return <FlushingScreen />
  } else if (currentScreen === "winning") {
    return <WinningScreen />
  } else if (currentScreen === "lost") {
    return <LostScreen />
  } else if (currentScreen === "noEvent") {
    return <NoEventScreen />
  } else {
    return <ConnectScreen />
  }

}

export default FlushPage;
