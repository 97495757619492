import { useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import numeral from 'numeral';
import React, { useContext, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router';
import { parsePkToFormat } from '../../common/misc';
import { TokenMint } from '../../common/types';
import { apiUrl } from '../../config';
import { UserContext } from '../../contexts/UserProvider';

interface SwapModalProps {
  closeModalCallback: () => void;
}

const SwapModal = ({ closeModalCallback }: SwapModalProps) => {
  const navigate = useNavigate();
  const { tokens, addToken } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: swapTokens, isPending } = useQuery({
    queryKey: ['swapTokens', searchTerm],
    queryFn: () =>
      fetch(`${apiUrl}/v1/tokens/swap?q=${searchTerm}`)
        .then((res) => res.json())
        .then((res) => res.data),
    refetchInterval: 60_000
  });

  useEffect(() => {
    const fetchClipboardText = async () => {
      try {
        const clipboardContent = await navigator.clipboard.readText();
        setSearchTerm(clipboardContent);
      } catch (err) {
        console.error('Failed to read clipboard contents: ', err);
      }
    };

    fetchClipboardText();
  }, []);

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setSearchTerm(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const setDisplayNone = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.style.display = 'none';
  };

  const getTokenImage = (tokenImage: string | null) => {
    if (!tokenImage || tokenImage.includes("https://cf-ipfs.com/ipfs/")) {
      return "/unknown-token.png";
    } else {
      return tokenImage;
    }
  }

  const content = (
    <>
      <h3 className="text-sm font-semibold tertiary mb-2">Trending</h3>
      <div className="overflow-y-scroll overflow-x-hidden">
        {swapTokens?.length > 0 && (
          <ul className="space-y-2 h-full">
            {swapTokens.map((token: TokenMint, index: number) => {
              let tokenValue: BigNumber | null = null;
              let tokenAmount: BigNumber | null = null;
              const ownedToken = tokens.find((t) => t.mint === token.mint);

              if (ownedToken && ownedToken.userToken !== null) {
                tokenAmount = new BigNumber(ownedToken.userToken.balanceDecimal);

                if (ownedToken.market !== null && ownedToken.market.price !== null) {
                  tokenValue = new BigNumber(ownedToken.userToken.balanceDecimal).multipliedBy(
                    ownedToken.market.price
                  );
                }
              }

              return (
                <li
                  onClick={() => {
                    closeModalCallback();
                    addToken(token);
                    setTimeout(() => {
                      navigate(`/tokens/${token.mint}`);
                    }, 500);
                  }}
                  key={token.mint}
                  className="flex items-center justify-between p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md">
                  <div className="flex items-center">
                    <img
                      src={getTokenImage(token.imageUrl)}
                      alt={token.name ?? 'Token'}
                      className="w-8 h-8 rounded-full mr-2"
                      onError={setDisplayNone}
                      draggable="false"
                    />
                    <div>
                      <p className="font-medium text-[1.14rem] dark:text-gray-300">{token.name}</p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {token.symbol.toUpperCase()}
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    {tokenAmount && (
                      <p className="font-semibold text-gray-700 dark:text-gray-300">
                        {numeral(tokenAmount.toFixed(2)).format('0.00a')}
                      </p>
                    )}
                    {tokenValue && (
                      <p className="font-semibold text-sm text-gray-700 dark:text-gray-300">
                        ${numeral(tokenValue.toFixed(2)).format('0.00a')}
                      </p>
                    )}
                    {!tokenValue && (
                      <p className="font-light text-base text-gray-700 dark:text-gray-300">
                        {parsePkToFormat(token.mint)}
                      </p>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );

  const noTokensContent = (
    <div className={`flex flex-col justify-center items-center h-full text-center mb-[50rem]`}>
      <img src='noData.png' alt='Loading nothing image' className='w-[48px]' />
      <p className='text-lg font-medium'>Nothing here</p>
    </div>
  )

  const loadingContent = (
    <div className={`flex flex-col justify-center items-center h-full text-center mb-[50rem]`}>
      <img src='eyes.png' alt='Loading eyes image' className='w-[48px]' />
      <p className='text-lg font-medium'>Checking the <br /> Solana Blockchain Technology</p>
    </div>
  )

  return (
    <div className="fixed flex flex-col items-center align-middle z-[1000] top-0 left-0 w-screen h-screen bg-black">
      <div className='max-w-lg w-full px-2 pt-3 mt-4 h-full'>
        <div className="relative mb-4 text-center">
          <div className="  ">
            <button
              className="absolute left-0 text-4xl opacity-50 hover:opacity-100"
              onClick={closeModalCallback}>
              <IoMdClose />
            </button>
          </div>

          <h2 className="mb-2 text-2xl">Find tokens</h2>
        </div>

        <div className="flex flex-col h-full m-auto w-full rounded-4xl bg-[#0D1012] p-4 shadow-lg">
          <div className="flex flex-col w-full py-4 px-0 h-full">
            <div className="relative mb-4">
              <div className="relative mb-4">
                <input
                  type="text"
                  placeholder="Type or paste CA"
                  className="w-full p-2 pl-10  rounded-2xl bg-[#090B0C] text-white  border-[#2c2e30] border"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <span className="absolute left-3 top-2.5 text-gray-400">🔍</span>
              </div>

              <button
                className="mb-4 h-14 border border-[#2c2e30] w-full flex items-center justify-center px-4 py-4 bg-[#161616] text-white rounded-3xl hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={handlePasteFromClipboard}>
                📋 Paste from clipboard
              </button>
            </div>
            {isPending ? loadingContent : (swapTokens === undefined || swapTokens.length) === 0 ? noTokensContent : content}
          </div>
        </div>
      </div>
    </div>

  );
};

export default SwapModal;
