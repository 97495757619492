export const parsePkToFormat = (str: string) => {
  if (!str || str.length < 3) {
    return str;
  }
  const firstPart = str.slice(0, 4);
  const lastPart = str.slice(-4);
  return `${firstPart}...${lastPart}`;
};

export const parsePkToPfFormat = (str: string) => {
  if (!str || str.length < 3) {
    return str;
  }
  return str.slice(0, 4);
};

/** Simple hash, from https://stackoverflow.com/a/8831937/4810109 */
function stringCode(str: string) {
  var hash = 0;
  if (str.length === 0) {
    return Math.abs(hash);
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

let lastEmoji: null | number = null;
export function mintToEmoji(mint: string) {
  const index = Math.floor(stringCode(mint));
  let i = index % emojis.length;

  while (lastEmoji !== null && i === lastEmoji) {
    i += 1;
  }

  return emojis[i];
}

const emojis = [
  '😚',
  '😳',
  '😎',
  '😇',
  '👽',
  '🔥',
  '✨',
  '🌟',
  '💫',
  '💥',
  '👀',
  '👌',
  '🙌',
  '🙏',
  '💪',
  '🙇',
  '🎩',
  '👑',
  '💎',
  '🐸',
  '🐵',
  '🐒',
  '🐴',
  '⭐',
  '🌊',
  '🎉',
  '🎊',
  '⏳',
  '⌛',
  '💣',
  '💊',
  '💴',
  '💵',
  '💷',
  '💶',
  '💸',
  '📊',
  '📈',
  '🎯',
  '🏆',
  '🎣',
  '🍻',
  '🎂',
  '🍭',
  '🍯',
  '🍎',
  '🎢',
  '🚢',
  '⛵',
  '🚤',
  '🚣',
  '🚀',
  '🎰',
  '🗿'
];
