export const extractDomain = (url: string | null): string => {
    try {
        if (url) {
            const parsedUrl = new URL(url);
            return parsedUrl.hostname; // Returns the domain (e.g. "www.example.com")
        }
    } catch (error) {
        return "null";
    }
    return "null"
};

export const extractXUsername = (url: string | null): string => {
    try {
        if (url) {
            const parsedUrl = new URL(url);
            if (parsedUrl.hostname.includes('x.com')) {
                const pathParts = parsedUrl.pathname.split('/').filter(Boolean);
                return pathParts[0] || "";
            }
        }
    } catch (error) {
        return "";
    }
    return "";
};

export const extractTelegramUsername = (url: string | null): string => {
    try {
        if (url) {
            const parsedUrl = new URL(url);
            if (parsedUrl.hostname.includes('t.me')) {
                const pathParts = parsedUrl.pathname.split('/').filter(Boolean);
                return pathParts[0] || "";
            }
        }
    } catch (error) {
        return "";
    }
    return "";
};