import { useFlushContext } from '../../contexts/flushContext/FlushContext';
import useProxyWallet from '../../hooks/proxyWallet';
import TimerBar from '../TimeBar';
import { FlushEvent } from '../types/flushTypes';

import base58 from 'bs58';
import { useContext } from 'react';
import { Emoji } from 'react-apple-emojis';
import { flushApiUrl } from '../../config';
import { TxContext } from '../../contexts/TxProvider';
import Lever from '../Lever';
import { usePostHog } from 'posthog-js/react';

const FlushScreen = () => {
  const posthog = usePostHog();

  const { checkData, secondsLeft, initialSeconds, setCurrentScreen, setFlushEvent } =
    useFlushContext();
  const { addPendingAlert } = useContext(TxContext);

  const { signMessage } = useProxyWallet();

  const pullStickHandler = async () => {
    if (!signMessage) {
      return;
    }
    let signature: string | null = null;
    try {
      posthog?.capture('flush-pull', { nonce: checkData?.nonce, ttl: checkData?.endsInt });

      const _signature = await signMessage(
        new TextEncoder().encode(`Confirming the Flush!\nnonce: ${checkData?.nonce}`)
      );
      signature = base58.encode(_signature);
    } catch (error: any) {
      addPendingAlert({
        successText: '',
        pendingText: '',
        failedText: `Failed to sign message: ${error.message ? error.message : error.name}`,
        status: 'failed'
      });
      return;
    }

    const response: FlushEvent = await fetch(`${flushApiUrl}/flush`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        publicKey: checkData?.publicKey,
        nonce: checkData?.nonce,
        signature
      })
    })
      .then((res) => res.json())
      .catch((error: any) => {
        addPendingAlert({
          successText: '',
          pendingText: '',
          failedText: `Failed to proceed with flush: ${error.message ? error.message : error.name}`,
          status: 'failed'
        });
      });

    setFlushEvent(response);
    setCurrentScreen('flushing');
  };
  return (
    <div className="h-screen w-full text-center">
      <div className="relative flex flex-col h-full w-full items-center justify-end gap-16 lg:gap-20">
        <div className="flex w-full items-center justify-end">
          <div className="absolute flex flex-col items-center right-[30%]">
            <p className="font-sf-black text-5xl">Flush to win</p>
            <Emoji name="backhand-index-pointing-right" className="move-lr" width={73} />
          </div>
          <Lever onAnimationCompleteHandler={pullStickHandler} />
        </div>
        <div className="w-full px-6 h-sm:mb-6 mb-[25vw] lg:mb-6">
          <TimerBar
            seconds={initialSeconds}
            secondsLeft={secondsLeft}
            endCallback={() => setCurrentScreen('noEvent')}
          />
        </div>
      </div>
    </div>
  );
};

export default FlushScreen;
