import React, { useCallback, useContext, useState } from 'react';

import { UserContext } from '../../contexts/UserProvider';
import BigNumber from 'bignumber.js';
import Modal from './Modal';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { SOL_MINT } from '../../common/constants';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SettingSmallCard from '../SettingSmallCard';
import PrimaryButton from '../ui/PrimaryButton';

export default (props: { onButtonCallback: () => void }) => {
  const {
    updateDefaultSolAmount,
    defaultBuySolAmount,
    slippage,
    updateSlippage,
    feedFilter,
    updateFeedFilter
  } = useContext(UserContext);
  const location = useLocation();
  const { tokens, tokensFetched } = useContext(UserContext);
  const navigate = useNavigate();

  const solToken = tokens.find((t) => t.mint === SOL_MINT);

  if (!tokensFetched) {
    return (
      <div className="flex h-screen p-5">
        <div className="m-auto">Loading</div>
      </div>
    );
  }

  if (solToken === undefined) {
    props.onButtonCallback();
    return <></>;
  }

  const balanceSol = Math.max(Number(solToken?.userToken?.balanceDecimal ?? 0) - 0.001, 0);
  const minSol = Math.max(Math.min(Number(solToken?.userToken?.balanceDecimal ?? 0), 0.001), 0);

  return (
    <Modal
      emoji={<>🚾</>}
      title={<>W Configurator</>}
      subtitle={<>Setup your feed to win</>}
      closeModalCallback={() => {
        if (location.state?.fromPortfolio !== undefined) {
          navigate('/main', {
            state: {
              fromPortfolio: location.state?.fromPortfolio
            }
          });
        } else {
          navigate(-1);
        }
      }}
      content={
        <div className="flex flex-col space-y-5">
          <SettingSmallCard
            title={'Market cap'}
            subtitle={"Size of coin's total value - small to large"}
            emoji={'🧢'}
            values={[
              { value: 1_000, display: '1k' },
              { value: 5_000, display: '5k' },
              { value: 10_000, display: '10k' },
              { value: 100_000, display: '100k' },
              { value: 500_000, display: '500k' },
              { value: 1_000_000, display: '1m' },
              { value: 10_000_000, display: '10m' },
              { value: 100_000_000, display: '100m' }
            ]}
            from={feedFilter.marketCap[0]}
            to={feedFilter.marketCap[1]}
            onAmountChange={(from, to) => {
              updateFeedFilter({ ...feedFilter, marketCap: [from, to] });
            }}
          />
          <SettingSmallCard
            title={'Time up'}
            subtitle={'How long it’s been around — new to old'}
            emoji={'⌛️'}
            values={[
              { value: 0, display: 'now' },
              // { value: 1, display: '1m' },
              // { value: 5, display: '5m' },
              { value: 30, display: '30m' },
              { value: 60, display: '1h' },
              { value: 60 * 3, display: '3h' },
              { value: 60 * 12, display: '12h' },
              { value: 60 * 24, display: '24h' },
              { value: 60 * 24 * 2, display: '2d' },
              { value: 60 * 24 * 3, display: '3d' },
              { value: 60 * 24 * 7, display: '7d' },
              { value: 60 * 24 * 30, display: '30d' }
            ]}
            from={feedFilter.creationDate[0]}
            to={feedFilter.creationDate[1]}
            onAmountChange={(from, to) => {
              console.log('creation date', from, to);
              updateFeedFilter({ ...feedFilter, creationDate: [from, to] });
            }}
          />
          <SettingSmallCard
            title={'Slippage'}
            subtitle={'The wiggle room you allow on price changes'}
            emoji={'🩸'}
            maxAmount={50}
            step={0.5}
            minAmount={0}
            to={slippage / 10}
            displayTransformer={(v) => `${v}%`}
            onAmountChange={(from, to) => {
              updateSlippage(to * 10);
            }}
          />
          <SettingSmallCard
            title={'Buy amount'}
            subtitle={"Amount you'll spend on each buying swipe"}
            emoji={'💸'}
            minAmount={minSol}
            maxAmount={Math.max(balanceSol, defaultBuySolAmount.div(LAMPORTS_PER_SOL).toNumber())}
            step={0.001}
            to={defaultBuySolAmount.div(LAMPORTS_PER_SOL).toNumber()}
            displayTransformer={(v) => `${v} SOL`}
            onAmountChange={(from, to) => {
              updateDefaultSolAmount(BigNumber(to).multipliedBy(LAMPORTS_PER_SOL));
            }}
          />
          <PrimaryButton
            text={`Confirm`}
            callback={() => {
              navigate('/main', {
                state: {
                  fromPortfolio: location.state?.fromPortfolio
                }
              });
            }}
            fullWidth={true}
          />
        </div>
      }
    />
  );
};
