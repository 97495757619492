import BigNumber from 'bignumber.js';
import numeral from 'numeral';
import Formatter from './Formatter';
import InputFormatter from './InputFormatter';
import { AmountSlider } from './ui/AmountSlider';
import PrimaryButton from './ui/PrimaryButton';

export default (props: {
  priceUsd?: number;
  inputBalance: BigNumber;
  inputAmount: BigNumber;
  inputAmountMax: BigNumber;
  inputAmountMin: BigNumber;
  inputDecimals: number;
  inputSymbol: string;
  inputImage: string | null;

  outputLoading: boolean;
  outputAmount: BigNumber | null;
  outputDecimals: number | null;
  outputSymbol: string | null;

  onAmountChange: (amount: BigNumber) => void;
  onInputAmountChange: (newValue: BigNumber) => void;
  loadingAmount: boolean;
  loadingHandler: React.Dispatch<React.SetStateAction<boolean>>;

  buttonText: string;
  onButtonCallback: (amount: BigNumber) => void;
}) => {
  return (
    <div className="balance-wrapper !w-full">
      <div className="p-5">
        <div className="text-center m-auto mb-2 flex justify-center">
          <p className="tertiary">Balance:</p>
          <p className="opacity-70 ml-1 flex row">
            <Formatter
              value={Number(props.inputBalance.div(10 ** props.inputDecimals))}
              numOfDigits={6}
            />{' '}
            &nbsp;
            {props.inputSymbol}
          </p>
        </div>
        <div className="text-center mb-4">
          <InputFormatter
            value={props.inputAmount.div(10 ** props.inputDecimals).toNumber()}
            maxAmount={props.inputAmountMax.div(10 ** props.inputDecimals).toNumber()}
            minAmount={props.inputAmountMin.div(10 ** props.inputDecimals).toNumber()}
            inputDecimals={props.inputDecimals}
            numOfDigits={3}
            className="text-8xl font-sf-bold slider-card-amount sm:text-6xl"
            amountChangeHandler={props.onInputAmountChange}
            loadingHandler={props.loadingHandler}
          />

          {props.priceUsd !== undefined && (
            <p className="opacity-40 mt-3">
              $
              {numeral(
                (
                  props.inputAmount.div(10 ** props.inputDecimals).toNumber() * props.priceUsd
                ).toFixed(4)
              ).format('0.00a')}
            </p>
          )}
        </div>
        <div className="mb-4">
          <AmountSlider
            amount={props.inputAmount}
            showAmount={false}
            decimals={props.inputDecimals}
            minAmount={props.inputAmountMin}
            maxAmount={props.inputAmountMax}
            setAmount={props.onAmountChange}
            imageUrl={props.inputImage ?? '/solana-blue-ico.svg'}
          />
        </div>

        {props.outputAmount !== null && props.outputDecimals !== null && (
          <div className="flex flex-row justify-between">
            <div className="font-xs font-bold tertiary opacity-50">You get:</div>
            {!props.outputLoading && (
              <div className="font-xs">
                {props.outputAmount
                  .div(10 ** props.outputDecimals)
                  .toNumber()
                  .toFixed(4)}{' '}
                {props.outputSymbol}
              </div>
            )}
          </div>
        )}

        <div className="pt-5">
          <PrimaryButton
            isDisabled={props.loadingAmount}
            text={props.buttonText}
            callback={() => {
              props.onButtonCallback(props.inputAmount);
            }}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
};
