import React from 'react';

import Formatter from '../components/Formatter';
import { Form, useNavigate } from 'react-router-dom';
import Autoscroller from '../components/Autoscroller';

const TestPage = () => {
    const navigate = useNavigate();

    return (
        <Autoscroller speed={1} className="testContainer">
            <Formatter value={1000} numOfDigits={10} />
            <Formatter value={0.001234567} numOfDigits={10} />
            <Formatter value={1234567890} numOfDigits={10} />
            <Formatter value={0.0000012345} numOfDigits={10} />
            <Formatter value={0.1234} />
            <Formatter value={1234} />
            <Formatter value={0.030405} />
            <Formatter value={0.00056021} />
        </Autoscroller>
    );
};

export default TestPage;