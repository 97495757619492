import { Emoji } from "react-apple-emojis";
import { MoreInfoItem } from "../types/tokenTypes";
import Modal from "./Modal";
import PrimaryButton from "../ui/PrimaryButton";

type TokenMoreInfoModalProps = {
    moreInfoItems: MoreInfoItem[];
    closeModalHandler: () => void;
    tokenName: string;
}

const TokenMoreInfoModal = ({ moreInfoItems, closeModalHandler, tokenName }: TokenMoreInfoModalProps) => {
    return <Modal title={<>More info</>} closeModalCallback={closeModalHandler} emoji={<Emoji name="information" className="w-[54px]" />} subtitle={<>When you need more than just vibes</>} content={
        <div className="more-info-wrapper flex flex-col gap-1.5 w-full py-2.5 px-3">
            <div className="flex gap-1">
                <p className="text-base opacity-40">Token:</p>
                <p className="text-base">{tokenName}</p>
            </div>
            <div className="flex flex-wrap gap-3">
                {moreInfoItems.map((infoItem) => (<div
                    key={infoItem.label}
                    className="flex items-center space-x-2 bg-[#171a1b] px-3 py-0.5 rounded-full">
                    {infoItem.icon && infoItem.icon.type === 'emoji' ? <span>{infoItem.icon.url}</span> : <img src={infoItem.icon?.url} />}{' '}
                    {infoItem.value && <span>{infoItem.value}</span>}
                    {infoItem.label && <span className="opacity-40">{infoItem.label}</span>}
                </div>))}
            </div>
            <button
                onClick={closeModalHandler}
                className="btn-secondary font-sf-bold px-6 py-2 border border-[#2c2e30] flex items-center justify-center bg-[#161616] text-white rounded-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
            >Close</button>
        </div>
    } />
}

export default TokenMoreInfoModal;