import Lottie from "lottie-react";
import flushAnimation from "../../assets/flush.json";
import { useFlushContext } from "../../contexts/flushContext/FlushContext";

const FlushingScreen = () => {
    const { flushEvent, setCurrentScreen } = useFlushContext();

    const flushCompleteHandler = () => {
        if (flushEvent?.amount === null) {
            setCurrentScreen("lost");
        } else {
            setCurrentScreen("winning");
        }
    }

    return (
        <div className="fixed top-[0px] left-[0px] w-[100vw] h-[100vh] z-[999] flex items-center justify-center">
            <Lottie
                animationData={flushAnimation}
                loop={false}
                autoplay={true}
                onComplete={flushCompleteHandler}
                className="scale-150"
            />
        </div>
    );
}

export default FlushingScreen;