import BigNumber from 'bignumber.js';
import React, { useEffect } from 'react';
import Formatter from '../Formatter';

export const AmountSlider = (props: {
  amount: BigNumber;
  decimals: number;
  minAmount: BigNumber;
  maxAmount: BigNumber;
  setAmount: (amount: BigNumber) => void;
  imageUrl: string;
  showAmount: boolean;
}) => {
  useEffect(() => {
    let percentage = props.amount
      .minus(props.minAmount)
      .div(props.maxAmount.minus(props.minAmount))
      .multipliedBy(100)
      .toNumber();
    percentage = percentage < 10 ? percentage + 1 : percentage;

    const trackGradient = `linear-gradient(to right, #ffffff00 0%, #ffffff00 5%, #A7F2FF 5%, #A7F2FF ${percentage}%, rgba(0, 0, 0, 0.4) ${percentage}%, rgba(0, 0, 0, 0.4) 100%)`;
    const backgroundGradient = `linear-gradient(to right, #23DDFF 0%, #23DDFF ${percentage}%, rgba(0, 0, 0, 0.4) ${percentage}%, rgba(0, 0, 0, 0.4) 100%)`;
    document.documentElement.style.setProperty('--range-track-gradient', trackGradient);
    document.documentElement.style.setProperty('--range-background-gradient', backgroundGradient);
  }, [props.amount, props.minAmount, props.maxAmount]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = new BigNumber(event.target.value);

    // Trigger vibration if supported
    if (navigator.vibrate) {
      navigator.vibrate(10); // Vibrate for 10ms
    }

    props.setAmount(newAmount);
  };
  return (
    <div className="range-wrapper">
      <input
        type="range"
        min={props.minAmount.toNumber()}
        max={props.maxAmount.toNumber()}
        value={props.amount.toNumber()}
        step={1 / 10 ** props.decimals}
        className="range"
        onChange={handleChange}
        style={{ background: `var(--range-background-gradient, #A7F2FF)` }}
      />
      <img src={props.imageUrl} alt="token-logo" className="sol-logo" />
      {props.showAmount && (
        <div className="amount w-12">
          <Formatter value={props.amount.toNumber()} numOfDigits={3} />
        </div>
      )}
    </div>
  );
};
