import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

const LostScreen = () => {
    const posthog = usePostHog();
    const navigate = useNavigate();

    const closeHandler = () => {
        posthog?.capture('flush-to-main', { from: 'lost-screen' });
        navigate('/main');
    };
    return (
        <div className="relative h-screen w-full flex flex-col items-center justify-center">
            <div className="relative flex flex-col items-center justify-center w-full h-full">
                <div className="relative flex flex-col items-center justify-center h-full w-full mb-32">
                    <div className="relative z-10 w-full flex flex-col items-center justify-center">
                        <div className="lost-screen-h1-wrapper">
                            <h1 data-text="NO LUCK" className='lost-screen-h1 lost-screen-text font-sf-black text-center'>NO LUCK</h1>
                        </div>
                        <img src={"/cryingToilet.png"} alt="Crying toilet image" className="lost-screen-show-up-img absolute top-[210px]" />
                    </div>
                </div>
                <div className="h-sm:mb-6 mb-[25vw] lg:mb-10 lost-screen-show-up-actions text-center flex flex-col items-center gap-3 w-full px-3">
                    <p className="font-sf-semibold text-lg opacity-40">Better luck next time</p>
                    <a className="btn-primary font-sf-bold w-full" href="http://t.me/swipedotfun">Join telegram</a>
                    <button className="btn-secondary border border-[#2c2e30] font-sf-bold w-full rounded-2xl bg-[#161616]" onClick={closeHandler}>
                        Back to Swipe
                    </button>
                </div>
            </div>

        </div>
    )
};

export default LostScreen;
