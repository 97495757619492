/*react component that gets height of its children and scrolls them if they overflow
Component scrolls continuously if children are taller than parent with a smooth animation and configurable speed.
If the end is reached, the component will wait for a second tap to scroll again and  change scroll direction.
Uses react-spring for animations.
*/

import { set } from 'lodash';
import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';


interface AutoscrollerProps {
    speed: number,
    children: React.ReactNode,
    className?: string,
    visible?: boolean
}




const Autoscroller: React.FC<AutoscrollerProps> = ({ speed, children, className }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState([0, 0]);


    const content = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            setContentHeight([node.scrollHeight, node.clientHeight]);
            node.style.setProperty('--slideY-value', -(node.scrollHeight - node.clientHeight) + "px");
            node.style.animation = `slideY ${node.scrollHeight / speed}s linear 0s infinite alternate`;

        }
    }, [children]);


    return (
        <div ref={containerRef} style={{ overflow: 'clip', width: '100%' }} className='autoscroller'>
            <div ref={content} className={className} >
                {children}
            </div>
        </div>
    );
};

export default Autoscroller;

