import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import WalletConnectionProvider from './contexts/WalletConnectionProvider';
import UserProvider from './contexts/UserProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SwipeProvider from './contexts/SwipeProvider';
import TxProvider from './contexts/TxProvider';

import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <QueryClientProvider client={queryClient}>
        <WalletConnectionProvider>
          <UserProvider>
            <SwipeProvider>
              <TxProvider>
                <App />
              </TxProvider>
            </SwipeProvider>
          </UserProvider>
        </WalletConnectionProvider>
      </QueryClientProvider>
    </PostHogProvider>
  </React.StrictMode>
);
