import React, { ReactElement } from 'react';
import { IoMdClose } from 'react-icons/io';

export default (props: {
  emoji?: ReactElement;
  title: ReactElement;
  subtitle?: ReactElement;
  content: ReactElement;
  closeModalCallback?: () => void;
}) => {
  return (
    <div className="flex h-screen overflow-y-scroll">
      <div className="m-auto w-full">
        {props.closeModalCallback && (
          <div className="absolute top-0 md:w-1/2 sm:w-full">
            <button
              className="absolute top-5 text-4xl opacity-50 hover:opacity-100"
              onClick={() => {
                if (props.closeModalCallback) {
                  props.closeModalCallback();
                }
              }}>
              <IoMdClose />
            </button>
          </div>
        )}

        <div className="flex flex-col justify-center mb-40">
          <div className="text-center mb-8 flex flex-col justify-center items-center">
            {props.emoji && <span role="img" aria-label="money with wings" className="text-6xl">
              {props.emoji}
            </span>}
            <h2 className="mb-2 text-2xl">{props.title}</h2>
            {props.subtitle && <p className="opacity-40">{props.subtitle}</p>}
          </div>
          {props.content}
        </div>
      </div>
    </div>
  );
};
