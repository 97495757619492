import Autoscroller from './Autoscroller';
import { useTimeAgo } from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { useEffect, useState } from 'react';
import { apiUrl } from '../config';
import { parsePkToPfFormat } from '../common/misc';
TimeAgo.addDefaultLocale(en);

const Threads = ({ mint, visible }: { mint: string; visible: boolean }) => {
  const [data, setData] = useState([] as any[]);

  //const data = mockData.filter((thread) => thread.mint === mint).reverse().slice(0, 10);
  useEffect(() => {
    if (visible) {
      fetch(`${apiUrl}/v1/tokens/${mint}/thread`)
        .then((response) => response.json())
        .then((json) => {
          //console.log(json);
          setData(json.data.replies.reverse().slice(0, 10));
        })
        .catch((error) => console.log(error));
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <div>
          <Autoscroller speed={30} className="threads p-4">
            {data.map((thread) => (
              <Thread key={(thread.key = thread.timestamp + thread.profileName)} thread={thread} />
            ))}
          </Autoscroller>
        </div>
      )}
    </>
  );
};

const Thread = (t: any) => {
  const thread = t.thread;

  const time = useTimeAgo({ date: new Date(thread.timestamp), locale: 'en' });

  return (
    <div className="thread ">
      <div className="thread__header">
        <div className="thread__user">
          {thread.profileName ?? parsePkToPfFormat(thread.profilePublicKey)}
        </div>
        <div className="thread__time">{time.formattedDate}</div>
      </div>
      <p>{thread.text}</p>
    </div>
  );
};

export default Threads;

const mockData = [
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153610,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: 'https://cf-ipfs.com/ipfs/QmbFEtE2tfy45seoCYaRg33N2Duru9tpGZLYpEjuaJHjWK',
    text: 'prepaying dex at 20K',
    user: 'GUDUMpoc65jFGCc2jdrWzW9AMB5DqgXZ1ot4qhbAqUMf',
    timestamp: 1720610572547,
    total_likes: 2,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153611,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto someobne?\n',
    user: 'Ao5GBcuvgyuWnvBnvLGjgN3zaxwZcvMqZ7fHTbu6MCN',
    timestamp: 1720610572714,
    total_likes: 2,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153671,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'someone make tg',
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720610656500,
    total_likes: 2,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153681,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto?',
    user: '3b93AgLXPaDdmJxpQgfPLum5Y7hFpt1WgfF5TMzYP2hz',
    timestamp: 1720610677493,
    total_likes: 2,
    username: 'CrypTzyy',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153698,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'bullish ticker, deserve cto',
    user: 'FNm4YsqFgMVppvynU43EKQVmdDHT13PczJxXD4b1vPs3',
    timestamp: 1720610711969,
    total_likes: 2,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153735,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto this, with meta it can easily send',
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720610804325,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153754,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'enjoy the ride',
    user: 'En9YMNXbueb8jeSHE6y24zvrdd1SBnLb3uznU66qdrJH',
    timestamp: 1720610855469,
    total_likes: 1,
    username: '78887',
    profile_image: 'https://cf-ipfs.com/ipfs/QmUvcTXyFUejRCCR3Jdft2dw3oqEN3hBtNZYz3URgkFczL',
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153764,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'fck it join the tg - https://t.me/PaperyDogCTO',
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720610876242,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153783,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: "I'll prepay for the dex and bumpbots",
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720610921287,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153872,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto https://t.me/PaperyDogCTO',
    user: 'En9YMNXbueb8jeSHE6y24zvrdd1SBnLb3uznU66qdrJH',
    timestamp: 1720611086376,
    total_likes: 1,
    username: '78887',
    profile_image: 'https://cf-ipfs.com/ipfs/QmUvcTXyFUejRCCR3Jdft2dw3oqEN3hBtNZYz3URgkFczL',
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153894,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto https://t.me/PaperyDogCTO',
    user: 'AykE2UtnxyF5j4Sn1ypTKWTUnK4kKT61Em574C6RdvCk',
    timestamp: 1720611135442,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153943,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'join up the vc',
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720611226023,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5153953,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'join tg lets moon this\n',
    user: 'En9YMNXbueb8jeSHE6y24zvrdd1SBnLb3uznU66qdrJH',
    timestamp: 1720611251291,
    total_likes: 1,
    username: '78887',
    profile_image: 'https://cf-ipfs.com/ipfs/QmUvcTXyFUejRCCR3Jdft2dw3oqEN3hBtNZYz3URgkFczL',
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154109,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'https://t.me/PaperyDogCTO legal!',
    user: '2fAhm5TkirG2WtCcKz9YdPeYCYiDjYWJFZh3GW58LFkL',
    timestamp: 1720611547961,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154149,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'CDev knows this job very well \nwill make dex payment\ntake your place\nhttps://t.me/PaperyDogCTO',
    user: 'AwoaRSixPGjYQJHjNuVSLaLLLiC3woQX1zo6NKJxhZzt',
    timestamp: 1720611597549,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154211,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'https://t.me/PaperyDogCTO',
    user: 'AwoaRSixPGjYQJHjNuVSLaLLLiC3woQX1zo6NKJxhZzt',
    timestamp: 1720611732711,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154242,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'It needs someone who will make the giant dex and trend payments and support it on telegram.',
    user: 'AwoaRSixPGjYQJHjNuVSLaLLLiC3woQX1zo6NKJxhZzt',
    timestamp: 1720611781137,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154264,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'tg deleted?',
    user: '3b93AgLXPaDdmJxpQgfPLum5Y7hFpt1WgfF5TMzYP2hz',
    timestamp: 1720611837105,
    total_likes: 1,
    username: 'CrypTzyy',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154275,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'CTO',
    user: '2fAhm5TkirG2WtCcKz9YdPeYCYiDjYWJFZh3GW58LFkL',
    timestamp: 1720611863008,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154282,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: '#5154264 yes',
    user: '2fAhm5TkirG2WtCcKz9YdPeYCYiDjYWJFZh3GW58LFkL',
    timestamp: 1720611877022,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154293,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: 'https://cf-ipfs.com/ipfs/Qmcm5zpoN9SXEgK4DjH9iJBHRUQ13pnQttmD6uvG5SNUXc',
    text: '6.5% ruined this',
    user: '2fAhm5TkirG2WtCcKz9YdPeYCYiDjYWJFZh3GW58LFkL',
    timestamp: 1720611917978,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154296,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: "that mfs is holding 6%, I don't want to get farmed by the dev",
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720611921904,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154306,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: "not sure if that's dev or some greedy bastard",
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720611943146,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154318,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: '6.5% needs to sell',
    user: 'GkbKLS44SmEQpyTGcz2krbeMc39SqDzvSuae9ubZcoPq',
    timestamp: 1720611954507,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154782,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'he sold, who wants to cto\n',
    user: 'FAx6zvX91Ke2CtWhN7bLm24Jggqdku8JV8byX4mivbUq',
    timestamp: 1720612863614,
    total_likes: 1,
    username: 'FAx6zv',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154792,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'guys\n',
    user: 'FAx6zvX91Ke2CtWhN7bLm24Jggqdku8JV8byX4mivbUq',
    timestamp: 1720612884990,
    total_likes: 1,
    username: 'FAx6zv',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154796,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'come on \n',
    user: 'FAx6zvX91Ke2CtWhN7bLm24Jggqdku8JV8byX4mivbUq',
    timestamp: 1720612890706,
    total_likes: 1,
    username: 'FAx6zv',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5154805,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: "let's do this",
    user: 'FAx6zvX91Ke2CtWhN7bLm24Jggqdku8JV8byX4mivbUq',
    timestamp: 1720612897345,
    total_likes: 1,
    username: 'FAx6zv',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5157590,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'LETS CTO THIS ILL MAKE TG',
    user: 'D759K7SDNpTBSztPSSX4UqVTowweVNbCWovxyAvvpo4u',
    timestamp: 1720617740971,
    total_likes: 1,
    username: 'noobinity',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5157662,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'cto coming\n',
    user: 'H5E8S2Rg4pF4h2FnasRtNSMhs9YXw5q1REjuWssMzHcF',
    timestamp: 1720617869816,
    total_likes: 1,
    username: null,
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5157679,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'papery dog?? SCAM',
    user: '4n4CN4ztSEUAfm2i6b4Mq1wNXFeg1uEHvuA1DvUz2n7W',
    timestamp: 1720617902460,
    total_likes: 1,
    username: 'HugeAss',
    profile_image: 'https://cf-ipfs.com/ipfs/QmXWe3dA79HBuJAwRvfnoC88BGbRAyoGJiPh1nyKZfvLQB',
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5157786,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'send me tg',
    user: '2eJmyohyaMhYni71wbYAGNMmJMDVAYQydmDaE7JmyXVp',
    timestamp: 1720618092081,
    total_likes: 2,
    username: 'aldin2908',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5159280,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: 'https://t.me/+KEGjNxLOUUU0Zjlh join the tg, we live in vc pushing this to dex\n',
    user: 'Bc65u5am4hq8EqhfPauEgWibLAarMA2LBVkMC5Ntu5YU',
    timestamp: 1720620251973,
    total_likes: 3,
    username: 'bc65u5dev',
    profile_image: null,
    liked_by_user: false
  },
  {
    signature: null,
    is_buy: null,
    sol_amount: null,
    id: 5160556,
    mint: 'C9tC73DLNE5JN9Kjy7onhE5fJfQiLHNeW3zaULcqQM31',
    file_uri: null,
    text: '#5159280 Yo thanks ',
    user: '5HJjfEiJvhPsGkzFpEkLvQk33bZT7xykkBs3HJSMqpUm',
    timestamp: 1720622025173,
    total_likes: 0,
    username: '5hjjfe',
    profile_image: null,
    liked_by_user: false
  }
];
