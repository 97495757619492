import numeral from 'numeral';

export const zerosMap: any = {
  2: '₂',
  3: '₃',
  4: '₄',
  5: '₅',
  6: '₆',
  7: '₇',
  8: '₈',
  9: '₉',
  10: '₁₀',
  11: '₁₁',
  12: '₁₂',
  13: '₁₃',
  14: '₁₄',
  15: '₁₅',
  16: '₁₆'
};

export function compactNumber(value: number, numOfDigits = 3) {
  let pValue = Math.abs(value);
  let strValue = '';
  let numOfZeroes = 0;

  if (pValue > 0 && pValue < 1) {
    numOfZeroes = -Math.floor(Math.log(pValue) / Math.log(10) + 1);

    if (numOfZeroes >= 3) {
      const aboveZero = Number((pValue * Math.pow(10, numOfZeroes)).toFixed(2)) * 10;

      strValue = `0.0${zerosMap[numOfZeroes] ?? '.'}${aboveZero.toString().slice(0, 1)}`;
    } else {
      strValue = value.toFixed(Math.max(3, numOfDigits));
    }
  } else if (value < 1000) {
    strValue = numeral(value).format('0,0.00');
  } else {
    strValue = numeral(value).format('0.00a');
  }

  return {
    strValue: Math.sign(value) === -1 ? '-' : '' + strValue
  };
}
