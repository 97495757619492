import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { CheckData, FlushEvent, FlushScreenType } from "../../components/types/flushTypes";

type FlushContextType = {
    currentScreen: FlushScreenType;
    setCurrentScreen: Dispatch<SetStateAction<FlushScreenType>>;
    checkData: CheckData | null;
    setCheckData: Dispatch<SetStateAction<CheckData | null>>;
    flushEvent: FlushEvent | null;
    setFlushEvent: Dispatch<SetStateAction<FlushEvent | null>>;
    initialSeconds: number;
    setInitialSeconds: Dispatch<SetStateAction<number>>;
    secondsLeft: number;
    setSecondsLeft: Dispatch<SetStateAction<number>>;
    handleInitialFlushNavigation: () => void;
}

const FlushContext = createContext<FlushContextType | undefined>(undefined);

export const useFlushContext = () => {
    const context = useContext(FlushContext);
    if (context === undefined) {
        throw new Error("useFlushContext must be used within a FlushProvider");
    }
    return context;
};

export default FlushContext;