import { apiUrl } from '../config';
import BigNumber from 'bignumber.js';
import { WSOL_MINT } from '../common/constants';

export async function SwapFromSolTx(
  side: 'buy' | 'sell',
  publicKey: string,
  outMint: string,
  inAmount: BigNumber,
  slippage: number,
  quoteResponse: any
): Promise<any | null> {
  let ref: null | string = null;
  const storedReferralData = localStorage.getItem('referralData');
  if (storedReferralData) {
    ref = JSON.parse(storedReferralData)?.referral ?? null;
  }

  const body = {
    inMint: side === 'buy' ? WSOL_MINT : outMint,
    outMint: side === 'buy' ? outMint : WSOL_MINT,
    slippage,
    inAmount: inAmount.integerValue().toString(),
    address: publicKey,
    quoteResponse,
    ref: ref ?? undefined,
    refTs: Math.floor(Date.now() / 1000)
  };

  try {
    const response = await fetch(`${apiUrl}/v1/swap/tx`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      console.error('HTTP error', response.status);
      return null;
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}
