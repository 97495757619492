import { Connection, VersionedTransaction } from '@solana/web3.js';
import { Buffer } from 'buffer';
import { usePostHog } from 'posthog-js/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useLocation } from 'react-router-dom';
import { SwapFromSolTx } from '../api/swipeApi';
import { TokenMint } from '../common/types';
import SwapModal from '../components/modals/SwapModal';
import TokenMoreInfoModal from '../components/modals/TokenMoreInfoModal';
import PortfolioView from '../components/PortfolioView';
import SwipeableStack from '../components/SwipeableStack';
import { rpcUrl } from '../config';
import { SwipeContext } from '../contexts/SwipeProvider';
import { TxContext } from '../contexts/TxProvider';
import { getRef, UserContext } from '../contexts/UserProvider';
import useProxyWallet from '../hooks/proxyWallet';
import { mapCardTokenToMoreInfoData } from '../components/utils/moreInfoUtils';
import { CardToken, MoreInfoItem } from '../components/types/tokenTypes';

const openedDrawerHeight = '95vh';
const closedDrawerSmallScreenHeight = '16vh';
const closedDrawerBigScreenHeight = '13vh';
const closedDrawerScreenHeightBreakpoint = 700;

const SwipePage = () => {
  const calculateClosedDrawerHeight = useCallback(
    () =>
      window.innerHeight > closedDrawerScreenHeightBreakpoint
        ? closedDrawerBigScreenHeight
        : closedDrawerSmallScreenHeight,
    []
  );
  const location = useLocation();

  const { swipeTokensStack, addTokenToHistory, addFailedMintToStack, addPendingTrade } =
    useContext(SwipeContext);
  const posthog = usePostHog();
  const { addPendingAlert } = useContext(TxContext);
  const { defaultBuySolAmount, setTokenSearch, tokenSearch, slippage } = useContext(UserContext);
  const { publicKey, sendTransaction } = useProxyWallet();
  const [initialLoad, setInitialLoad] = useState(true);
  const [drawerHeight, setDrawerHeight] = useState(
    location.state?.fromPortfolio !== undefined ? openedDrawerHeight : calculateClosedDrawerHeight()
  );
  const [moreInfoData, setMoreInfoData] = useState<{
    infoItems: MoreInfoItem[];
    tokenName: string;
  } | null>(null);
  const startYRef = useRef(0);

  const isDrawerOpened = useCallback(() => drawerHeight === openedDrawerHeight, [drawerHeight]);

  useEffect(() => {
    if (location.state?.fromPortfolio) {
      document.getElementById('portfolio')?.scrollTo(0, location.state?.fromPortfolio);
    }
    const handleResize = () => {
      setDrawerHeight((prevHeight) =>
        prevHeight === openedDrawerHeight ? prevHeight : calculateClosedDrawerHeight()
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const buyToken = useCallback(
    async (token: TokenMint) => {
      if (!publicKey) {
        return alert('Missing pk');
      }

      try {
        posthog?.capture('swipe_token', {
          token: token.mint,
          platform: token.swappable,
          forAmount: defaultBuySolAmount.toString(),
          forDecimals: 9,
          ref: getRef()
        });
      } catch (e) {
        console.error(e);
      }

      const swapTxs = await SwapFromSolTx(
        'buy',
        publicKey.toBase58(),
        token.mint,
        defaultBuySolAmount,
        slippage,
        null
      );

      try {
        if (!swapTxs.txs) {
          throw new Error('No swap tx');
        }
        const txs = swapTxs.txs.map((txData: string) => {
          return VersionedTransaction.deserialize(Buffer.from(txData, 'base64'));
        });
        const signature = await sendTransaction(txs[0], new Connection(rpcUrl));
        addPendingTrade(signature, token, swapTxs.quote ?? null);

        try {
          posthog?.capture('swiped_token', {
            token: token.mint,
            signature,
            platform: token.swappable,
            forAmount: defaultBuySolAmount.toString(),
            forDecimals: 9,
            ref: getRef()
          });
        } catch (e) {
          console.error(e);
        }
      } catch (error: any) {
        console.log(JSON.stringify(error, null, 1));
        addPendingAlert({
          successText: '',
          pendingText: '',
          failedText: `Failed to send tx: ${error.message ? error.message : error.name}`,
          status: 'failed'
        });

        try {
          posthog?.capture('swipe_token_failed', {
            token: token.mint,
            platform: token.swappable,
            error: error.message ? error.message : error.name,
            ref: getRef()
          });
        } catch (e) {
          console.error(e);
        }

        setTimeout(() => {
          addFailedMintToStack(token.mint);
        }, 200);
      } finally {
        addTokenToHistory(token);
      }
    },
    [
      addTokenToHistory,
      addPendingTrade,
      defaultBuySolAmount,
      publicKey,
      sendTransaction,
      addPendingAlert,
      addFailedMintToStack
    ]
  );

  useEffect(() => {
    if (initialLoad && swipeTokensStack.length > 0) {
      setInitialLoad(false);
    }
  }, [swipeTokensStack, initialLoad]);

  const handleTouchStart = (e: React.TouchEvent) => {
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const endY = e.touches[0].clientY;
    if (startYRef.current - endY > 50) {
      handleDrawerOpen();
      navigator.vibrate(50); // Haptic feedback for drawer open
    } else if (endY - startYRef.current > 50) {
      handleDrawerClose();
      navigator.vibrate(50); // Haptic feedback for drawer close
    }
  };

  const handleDrawerOpen = () => {
    setDrawerHeight(openedDrawerHeight);
  };

  const handleDrawerClose = () => {
    document.getElementById('portfolio')?.scrollTo(0, 0);
    setDrawerHeight(calculateClosedDrawerHeight());
  };

  const openMoreInfoModalHandler = (token: CardToken) => {
    setMoreInfoData(mapCardTokenToMoreInfoData(token));
  };

  if (moreInfoData) {
    return (
      <div className="p-5 w-full">
        <TokenMoreInfoModal
          moreInfoItems={moreInfoData.infoItems}
          tokenName={moreInfoData.tokenName}
          closeModalHandler={() => setMoreInfoData(null)}
        />
      </div>
    );
  }

  if (tokenSearch) {
    return <SwapModal closeModalCallback={() => setTokenSearch(false)} />;
  }

  return (
    <div className=" h-screen w-full px-5">
      {swipeTokensStack.length !== 0 && (
        <SwipeableStack
          initial={initialLoad}
          cards={swipeTokensStack.slice().reverse()}
          swipeRight={(token) => {
            buyToken(token);
          }}
          swipeLeft={(token) => {
            setTimeout(() => {
              addTokenToHistory(token);
            }, 100);

            try {
              posthog?.capture('fade_token', {
                token: token.mint,
                platform: token.swappable,
                ref: getRef()
              });
            } catch (e) {
              console.error(e);
            }
          }}
          openMoreInfoModal={(token: CardToken) => openMoreInfoModalHandler(token)}
        />
      )}
      <Drawer
        open={true}
        onClose={handleDrawerClose}
        direction="bottom"
        // size={drawerHeight}
        className="drawer-content drawer-height"
        enableOverlay={false}
        lockBackgroundScroll={false}
        style={{ backgroundColor: 'black', height: `${drawerHeight}` }}>
        <div
          className={`relative  rounded-t-4xl rounded-b-none  border border-[#2c2e30] shadow-lg bg-[#0d1012] p-3 ${isDrawerOpened() ? 'overflow-y-auto' : 'overflow-y-hidden'} overflow-x-hidden h-[95vh]`}>
          <div
            className="w-full h-12 absolute rounded block sm:hidden"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}></div>
          <div className="w-16 mx-auto h-1 bg-[#2c2e30] rounded block sm:hidden"></div>

          <div className="mx-auto h-1 w-full text-center hidden sm:block">
            {isDrawerOpened() ? (
              <button onClick={handleDrawerClose}>
                <FaChevronDown className="text-white" />
              </button>
            ) : (
              <button onClick={handleDrawerOpen}>
                <FaChevronUp className="text-white" />
              </button>
            )}
          </div>

          <div
            id="portfolio"
            className={`mt-2 p-2 h-full ${isDrawerOpened() ? 'overflow-y-auto' : 'overflow-y-hidden'}`}>
            <PortfolioView
              showSlider={!isDrawerOpened()}
              touchStartHandler={handleTouchStart}
              touchMoveHandler={handleTouchMove}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SwipePage;
