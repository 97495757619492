import { useUnifiedWalletContext } from '@jup-ag/wallet-adapter';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Emoji } from 'react-apple-emojis';
import { flushApiUrl } from '../../config';
import { useFlushContext } from '../../contexts/flushContext/FlushContext';
import useProxyWallet from '../../hooks/proxyWallet';
import TimerBar from '../TimeBar';
import PrimaryButton from '../ui/PrimaryButton';
import { getRef } from '../../contexts/UserProvider';
import { usePostHog } from 'posthog-js/react';

const ConnectScreen = () => {
  const posthog = usePostHog();

  const { setShowModal } = useUnifiedWalletContext();
  const { connected, publicKey } = useProxyWallet();
  const {
    checkData,
    setCheckData,
    setCurrentScreen,
    setSecondsLeft,
    setInitialSeconds,
    handleInitialFlushNavigation
  } = useFlushContext();

  const { data: _checkData, refetch } = useQuery({
    queryKey: ['flushCheck'],
    queryFn: () =>
      fetch(`${flushApiUrl}/check?publicKey=${publicKey ? publicKey.toBase58() : ''}`).then((res) =>
        res.json()
      ),
    enabled: false,
    refetchInterval: 10000
  });

  useEffect(() => {
    if (connected) {
      if (publicKey) {
        posthog?.identify(publicKey.toBase58());
        posthog?.capture('connect', {
          publicKey: publicKey.toBase58(),
          ref: getRef(),
          entry: 'flush',
          ttl: checkData?.endsInt
        });
      }

      if (publicKey && (!checkData || !checkData?.publicKey)) {
        refetch();
      } else if (publicKey) {
        handleInitialFlushNavigation();
      }
    } else {
      setInitialSeconds(checkData?.endsInt as number);
    }
  }, [connected, publicKey, checkData]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (_checkData) {
      setCheckData(_checkData);
    }
  }, [_checkData]);

  const timeUpdateHandler = (secondsLeft: number) => {
    setSecondsLeft(secondsLeft);
  };
  return (
    <div className="h-screen w-full text-center flex flex-col items-center justify-center">
      <div className="flex flex-col w-full h-full items-center justify-end h-sm:gap-36 gap-52 lg:gap-64">
        <div className="flex flex-col items-center justify-end h-sm:gap-4 gap-8">
          <Emoji name="toilet" width={73} />
          <div>
            <p className="font-sf-black  text-5xl">Flush!</p>
            <p className="font-sf-semibold text-lg opacity-40">Connect to take part in airdrop</p>
          </div>
          <PrimaryButton
            children={<>Connect wallet</>}
            onClick={() => setShowModal(true)}
            classname="w-[256px]"
          />
        </div>
        <div className="w-full px-6 mb-[25vw] h-sm:mb-6 lg:mb-6">
          {checkData?.endsInt && (
            <TimerBar
              seconds={checkData.endsInt!}
              endCallback={() => setCurrentScreen('noEvent')}
              timeUpdateCallback={timeUpdateHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectScreen;
