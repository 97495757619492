import { useDebounce } from '@uidotdev/usehooks';
import BigNumber from 'bignumber.js';
import { ChangeEvent, useEffect, useState } from 'react';
import { compactNumber } from '../common/numbers';

const InputFormatter = (props: {
  value: number;
  maxAmount: number;
  minAmount: number;
  inputDecimals: number;
  numOfDigits?: number;
  className?: string;
  amountChangeHandler: (newValue: BigNumber) => void;
  loadingHandler: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [inputValue, setInputValue] = useState('');
  const [displayCompact, setDisplayCompact] = useState(true);
  const [isInputValid, setIsInputValid] = useState(true);
  const debouncedInput = useDebounce(inputValue, 500);

  const isInputValueValid = (newNumberValue: number) =>
    !isNaN(newNumberValue) &&
    newNumberValue !== 0 &&
    newNumberValue <= props.maxAmount &&
    newNumberValue >= props.minAmount;

  useEffect(() => {
    const numericDebouncedInput = Number(debouncedInput);
    if (numericDebouncedInput === 0) {
      setIsInputValid(false);
    } else if (isInputValueValid(numericDebouncedInput)) {
      props.amountChangeHandler(BigNumber(debouncedInput).multipliedBy(10 ** props.inputDecimals));
      setDisplayCompact(true);
      setIsInputValid(true);
      props.loadingHandler(false);
    }
  }, [debouncedInput]);

  useEffect(() => {
    setDisplayCompact(true);
    setIsInputValid(true);
  }, [props.value]);

  const numOfDigits = props.numOfDigits ?? 10;

  const onFocusHandler = () => {
    setDisplayCompact(false);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    props.loadingHandler(true);
    let newValue = e.target.value;
    if (newValue.slice(-1) === ',') {
      newValue = newValue.replace(',', '.');
    }
    const newNumberValue = Number(newValue);
    setInputValue(newValue);
    if (newNumberValue === 0) {
      setIsInputValid(true);
    } else if (!isInputValueValid(newNumberValue)) {
      setIsInputValid(false);
    } else {
      setIsInputValid(true);
      props.loadingHandler(false);
    }
    setDisplayCompact(false);
  };

  const { strValue } = compactNumber(props.value, numOfDigits);

  return (
    <div className={'formAmountCont ' + props.className}>
      <input
        type="text"
        inputMode="decimal"
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        value={displayCompact ? strValue : inputValue}
        className={`form-amount-input ${!isInputValid && 'form-amount-input-invalid'}`}
      />
    </div>
  );
};

export default InputFormatter;
