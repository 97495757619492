import React, { useEffect } from 'react';

import { Range, getTrackBackground } from 'react-range';
import { keyBy } from 'lodash';

export const RangeSlider = (props: {
  rangeValues?: { value: number; display: string }[];
  minValue?: number;
  maxValue?: number;
  step?: number;
  from?: number;
  to: number;
  onAmountChange: (from: number, to: number) => void;
  displayTransformer?: (value: number) => string;
}) => {
  const rangeMap = props.rangeValues?.map((e) => e.value) ?? [];

  if (rangeMap.length > 0) {
    if (rangeMap.indexOf(props.from as number) === -1) {
      props.from = rangeMap[0];
    }
    if (rangeMap.indexOf(props.to as number) === -1) {
      props.to = rangeMap[rangeMap.length - 1];
    }
  }

  const [values, setValues] = React.useState(
    props.maxValue !== undefined
      ? [props.to]
      : [rangeMap.indexOf(props.from as number), rangeMap.indexOf(props.to as number)]
  );

  useEffect(() => {
    if (values.length === 1) {
      props.onAmountChange(-1, values[0]);
    } else {
      props.onAmountChange(rangeMap[values[0]], rangeMap[values[1]]);
    }
  }, [values]);
  if (props.maxValue === undefined && props.rangeValues === undefined) {
    return <>Invalid range</>;
  }

  const STEP = props.step ?? 1;
  const MIN = props.minValue ?? 0;
  const MAX = props.maxValue ?? (props.rangeValues ? props.rangeValues.length - 1 : 10);

  return (
    <div className="flex items-center align-middle">
      {values.length !== 1 && (
        <div className="w-12 mr-5 -ml-2 text-sm text-center font-sf-heavy overflow-hidden">
          {props.rangeValues && props.rangeValues[values[0]]
            ? props.rangeValues[values[0]].display
            : 'n/a'}
        </div>
      )}
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={false}
        onChange={(values) => {
          if (values.length === 1) {
            if (props.maxValue !== undefined && values[0] > props.maxValue) {
              setValues([props.maxValue]);
            } else {
              setValues(values);
            }
          } else if (values[0] !== values[1]) {
            setValues(values);
          }
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}>
            <div
              ref={props.ref}
              style={{
                height: '14px',
                width: '100%',
                borderRadius: '100px',
                background: getTrackBackground({
                  values,
                  colors:
                    values.length === 1 ? ['#23DDFF', 'black'] : ['black', '#23DDFF', 'black'],
                  min: MIN,
                  max: MAX,
                  rtl: false
                }),
                alignSelf: 'center'
              }}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: '21px',
              width: '21px',
              borderRadius: '999px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px solid #232627'
            }}></div>
        )}
      />
      <div className="w-16 ml-6 -mr-2 text-sm text-center font-sf-heavy overflow-hidden">
        {props.rangeValues && props.rangeValues[values[1]]
          ? props.rangeValues[values[1]].display
          : props.displayTransformer
            ? props.displayTransformer(values[0])
            : values[0]}
      </div>
    </div>
  );
};
