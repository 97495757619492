import BigNumber from 'bignumber.js';
import { TokenMint } from '../common/types';
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { apiUrl } from '../config';
import useProxyWallet from '../hooks/proxyWallet';

export type FeedFilter = {
  marketCap: [number, number];
  creationDate: [number, number];
};

const defaultFeedFilter: FeedFilter = {
  marketCap: [1_000, 100_000],
  creationDate: [30, 60 * 24 * 3]
};

const defaultSlippage = 50;

type UserContextType = {
  tokenSearch: boolean;
  setTokenSearch: (set: boolean) => void;
  defaultBuySolAmount: BigNumber;
  updateDefaultSolAmount: (amount: BigNumber) => void;
  slippage: number;
  feedFilter: FeedFilter;
  updateFeedFilter: (value: FeedFilter) => void;
  updateSlippage: (amount: number) => void;
  refetchBalance: () => void;
  tokensFetched: boolean;
  tokens: TokenMint[];
  addToken: (token: TokenMint) => void;
  portfolioValueUsd: {
    total: string | null;
    change: string | null;
    changePercentage: string | null;
  } | null;
};

export const UserContext = createContext<UserContextType>({
  defaultBuySolAmount: BigNumber(0),
  updateDefaultSolAmount: (amount) => {},
  slippage: defaultSlippage,
  feedFilter: defaultFeedFilter,
  updateFeedFilter: (value) => {},
  updateSlippage: (amount) => {},
  refetchBalance: () => {},
  tokens: [],
  tokensFetched: false,
  portfolioValueUsd: null,
  setTokenSearch: (set) => {},
  addToken: (token) => {},
  tokenSearch: false
});

const lsKey_defaultSolAmount = 'set_defaultSolAmount';
const lsKey_slippage = 'set_slippage';
const lsKey_feedFilter = 'set_feedFilter';
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [tokenSearch, setTokenSearch] = useState(false);
  const [addedTokens, setAddedTokens] = useState<TokenMint[]>([]);

  const { publicKey } = useProxyWallet();

  const [tokensFetched, setTokensFetched] = useState(false);

  const _defAmount =
    localStorage.getItem(lsKey_defaultSolAmount) !== null
      ? BigNumber(localStorage.getItem(lsKey_defaultSolAmount) as string)
      : BigNumber(0);
  const [defaultBuySolAmount, setDefaultSolAmount] = useState(_defAmount);
  useEffect(() => {
    localStorage.setItem(lsKey_defaultSolAmount, defaultBuySolAmount.toString());
  }, [defaultBuySolAmount]);
  const updateDefaultSolAmount = useCallback(
    (amount: BigNumber) => {
      if (amount.gt(0)) {
        setDefaultSolAmount(amount);
      }
    },
    [setDefaultSolAmount]
  );

  const _slippage =
    localStorage.getItem(lsKey_slippage) !== null
      ? Number(localStorage.getItem(lsKey_slippage) as string)
      : Number(50);
  const [slippage, setSlippage] = useState(_slippage);
  useEffect(() => {
    localStorage.setItem(lsKey_slippage, slippage.toString());
  }, [slippage]);
  const updateSlippage = useCallback(
    (amount: number) => {
      if (amount >= 0 && amount <= 500) {
        setSlippage(amount);
      }
    },
    [setSlippage]
  );

  const _feedFilter =
    localStorage.getItem(lsKey_feedFilter) !== null
      ? (JSON.parse(localStorage.getItem(lsKey_feedFilter) as string) as FeedFilter)
      : defaultFeedFilter;
  const [feedFilter, setFeedFilter] = useState(_feedFilter);
  useEffect(() => {
    localStorage.setItem(lsKey_feedFilter, JSON.stringify(feedFilter));
  }, [feedFilter]);
  const updateFeedFilter = useCallback(
    (value: FeedFilter) => {
      setFeedFilter(value);
    },
    [setFeedFilter]
  );

  const queryClient = useQueryClient();

  const addToken = useCallback(
    (token: TokenMint) => {
      setAddedTokens((prevStateArray) => [...prevStateArray, token]);
    },
    [setAddedTokens]
  );
  const refetchBalance = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['userTokens'] });
  }, []);

  const { data: tokensData } = useQuery({
    queryKey: ['userTokens'],
    queryFn: () =>
      fetch(`${apiUrl}/v1/sol/tokens?address=${publicKey?.toBase58()}`).then((res) => res.json()),
    enabled: publicKey !== null,
    refetchInterval: 10000
  });

  if (tokensData?.data?.tokens && !tokensFetched) {
    setTokensFetched(true);
  }

  return (
    <UserContext.Provider
      value={{
        addToken,
        updateSlippage,
        slippage,
        feedFilter,
        updateFeedFilter,
        setTokenSearch,
        tokenSearch,
        tokensFetched,
        refetchBalance,
        defaultBuySolAmount,
        updateDefaultSolAmount,
        tokens: tokensData?.data?.tokens ? [...tokensData?.data?.tokens, ...addedTokens] : [],
        portfolioValueUsd: tokensData?.data?.portfolioValueUsd ?? null
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const getRef = () => {
  let ref: null | string = null;
  const storedReferralData = localStorage.getItem('referralData');
  if (storedReferralData) {
    ref = JSON.parse(storedReferralData)?.referral ?? null;
  }
  return ref;
};

export default UserProvider;
