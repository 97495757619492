import { Emoji } from 'react-apple-emojis';
import PrimaryButton from '../ui/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';

const NoEventScreen = () => {
    const posthog = usePostHog();
    const navigate = useNavigate();

    const backToSwipe = () => {
        posthog?.capture('flush-to-main', { from: 'no-event-screen' });
        navigate('/main');
    };
    const openAppOrFallback = (appUrl: string, fallbackUrl: string) => {
        const startTime = new Date().getTime();

        window.location.href = appUrl;

        setTimeout(() => {
            const elapsedTime = new Date().getTime() - startTime;

            if (elapsedTime < 2000) {
                window.location.href = fallbackUrl;
            }
        }, 1500);
    };

    return (
        <div className="h-screen w-full text-center">
            <div className="relative flex flex-col h-full items-center justify-center">
                <div className="flex flex-col h-full items-center justify-center gap-8 mb-6 h-md:mb-0">
                    <div className="flex">
                        <Emoji name="oncoming-fist" width={73} />
                        <Emoji name="clown-face" width={73} />
                    </div>

                    <div>
                        <p className="font-sf-black text-5xl">Too late</p>
                        <p className="font-sf-semibold text-lg opacity-40">Turn on notifications for new posts</p>
                    </div>
                    <div className="flex flex-col gap-2.5 w-[256px]">
                        <button className="btn-secondary leading-[52px] border border-[#2c2e30] font-sf-bold w-full rounded-2xl bg-[#161616] flex items-center justify-center" onClick={() =>
                            openAppOrFallback("twitter://user?screen_name=swipedotfun", "https://x.com/swipedotfun")
                        }>
                            Follow on Twitter
                        </button>
                        <button className="btn-secondary leading-[52px] border border-[#2c2e30] font-sf-bold w-full rounded-2xl bg-[#161616] flex items-center justify-center" onClick={() =>
                            openAppOrFallback("tg://resolve?domain=swipedotfun", "http://t.me/swipedotfun")
                        }>
                            Join TG
                        </button>
                        <button className="btn-secondary leading-[52px] border border-[#2c2e30] font-sf-bold w-full rounded-2xl bg-[#161616] flex items-center justify-center" onClick={() =>
                            openAppOrFallback("snssdk1128://user/profile/username=swipedotfun", "https://www.tiktok.com/@swipedotfun")
                        }>
                            Follow on TikTok
                        </button>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-5 h-sm:mb-[10vw] mb-[23vw] lg:mb-20">
                    <p className="font-sf-semibold text-lg opacity-40">Swiping increases your chances for big rewards</p>
                    <PrimaryButton children={<>Back to swiping</>} onClick={backToSwipe} classname="w-full" />
                </div>
            </div>
        </div>
    )
};

export default NoEventScreen;
