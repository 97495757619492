import { useEffect, useState } from 'react';

type TimeBarProps = {
  seconds: number;
  secondsLeft?: number;
  endCallback?: () => void;
  timeUpdateCallback?: (secondsLeft: number) => void;
}

const formatTime = (seconds: number) => {
  if (!seconds) {
    return "0";
  }
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const TimerBar = ({ seconds, endCallback, timeUpdateCallback, secondsLeft }: TimeBarProps) => {
  const [timeLeft, setTimeLeft] = useState(secondsLeft ? secondsLeft : seconds);
  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerIntervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          if (endCallback) {
            endCallback();
          }
          clearInterval(timerIntervalId);
          return 0;
        }
        if (timeUpdateCallback) {
          timeUpdateCallback(prevTime - 1);
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerIntervalId);
  }, [timeLeft]);

  const percLeft = !seconds ? 0 : timeLeft / seconds * 100;

  return (
    <>
      <div className='font-sf-bold text-5xl mb-5'>
        {formatTime(timeLeft)}
      </div>
      <div
        className='w-full h-4 bg-white rounded-2xl relative'
      >
        <div
          className="relative h-full rounded-2xl bg-gradient-to-b from-[#FFBB24] to-[#997226] transition-width duration-1000 ease-linear"
          style={{
            width: `${percLeft}%`,
          }}
        >
          <div className='absolute top-1 left-1 h-[3px] w-[97%] bg-white opacity-30 rounded-2xl'></div>
        </div>
      </div>
    </>
  );
};

export default TimerBar;