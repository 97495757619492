import { createChart } from 'lightweight-charts';
import { Suspense, useEffect, useRef, useContext } from 'react';

import { compactNumber } from '../common/numbers';

import BigNumber from 'bignumber.js';
import { UserContext } from '../contexts/UserProvider';
import { SOL_MINT } from '../common/constants';

// react component that renders a chart for a given mint

type Props = {
  chartData: any;
  //solPrice : number;
  visible?: boolean;
};

const MiniChart = ({ visible, chartData }: Props): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { tokens } = useContext(UserContext);

  const solPrice = parseFloat(tokens.find((t) => t.mint === SOL_MINT)?.market?.price ?? '180');

  useEffect(() => {
    if (!visible || !chartData || !chartData.items) {
      return;
    }

    //create chart
    const chart = createChart(chartRef.current!, {
      width: 300,
      height: 200,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        rightOffset: 4,
        borderVisible: false,
        barSpacing: 100,
        visible: false
      },
      layout: {
        background: {
          color: 'transparent'
        },
        textColor: '#ffffff'
      },
      grid: {
        vertLines: { visible: false }
      }
    });
    //const series = chart.addCandlestickSeries();
    //const series = chart.addAreaSeries();
    const series = chart.addLineSeries({
      priceLineColor: '#b3f7f9',
      priceLineWidth: 1
    });

    const data = chartData.items.map((candle: any) => {
      return {
        time: candle.timestamp,
        open: candle.o,
        high: candle.h,
        low: candle.l,
        close: candle.c,
        volume: candle.v
      };
    });

    const dataArea = chartData.items.map((candle: any) => {
      return {
        time: candle.timestamp,
        value: candle.c
      };
    });

    series.setData(dataArea);

    series.priceFormatter().format = (price: number) => {
      const { strValue } = compactNumber(
        BigNumber(price).multipliedBy(1_000_000_000).multipliedBy(solPrice).toNumber()
      );
      return strValue;
    };

    const min = Math.min(...data.map((candle: any) => candle.low));
    const max = Math.max(...data.map((candle: any) => candle.high));

    series.createPriceLine({ price: min, color: 'gray', lineWidth: 1, lineStyle: 0 });
    series.createPriceLine({ price: max, color: 'gray', lineWidth: 1, lineStyle: 0 });

    //chart.timeScale().fitContent()
    //const offset = dataArea.length > 240 ? 480 : dataArea.length > 120 ? 240 : dataArea.length > 60 ? 120 : 0
    chart.timeScale().setVisibleLogicalRange({ from: dataArea.length - 360, to: dataArea.length });
    //console.log(dataArea)
  }, [visible]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="chart-container">
        <div id={'chart'} className="mini-chart" ref={chartRef}></div>
      </div>
    </Suspense>
  );
};

export default MiniChart;
