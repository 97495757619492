import React, { useState } from 'react';

import { RangeSlider } from './ui/RangeSlider';

export default (props: {
  title: string | null;
  subtitle: string | null;
  emoji: string | null;
  values?: { value: number; display: string }[];
  displayTransformer?: (value: number) => string;
  maxAmount?: number;
  minAmount?: number;
  from?: number;
  step?: number;
  to: number;
  onAmountChange: (from: number, to: number) => void;
}) => {
  return (
    <div className="balance-wrapper !w-full">
      <div className="p-5">
        <div className="m-auto mb-2 flex justify-between">
          <div>
            {props.title !== null && <p className="text-3xl">{props.title}</p>}
            {props.subtitle !== null && (
              <p className="font-sf-thin text-sm secondary">{props.subtitle}</p>
            )}
          </div>
          <div>
            {props.emoji && (
              <span role="img" aria-label="money with wings" className="text-4xl">
                {props.emoji}
              </span>
            )}
          </div>
        </div>

        <div className="mt-5">
          <RangeSlider
            rangeValues={props.values}
            maxValue={props.maxAmount}
            minValue={props.minAmount}
            from={props.from}
            to={props.to}
            step={props.step}
            onAmountChange={props.onAmountChange}
            displayTransformer={props.displayTransformer}
          />
        </div>
      </div>
    </div>
  );
};
