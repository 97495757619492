import { CardToken, MoreInfoItem } from "../types/tokenTypes";

export const mapCardTokenToMoreInfoData = (token: CardToken) => {
    const infoItems: MoreInfoItem[] = token.tags.map((tag) => {
        return {
            label: tag.label,
            icon: {
                url: tag.emoji,
                type: 'emoji'
            },
            value: tag.text
        }
    }).concat(token.tokenSocials.map((social) => {
        return {
            label: social.name,
            icon: {
                url: social.icon,
                type: 'img'
            },
            value: social.value,
            link: social.link
        }
    }));

    return { infoItems, tokenName: token.name }
}