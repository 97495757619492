import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import leverAnimation from "../assets/lever.json";

type LeverProps = {
    onAnimationCompleteHandler: () => void;
}

const Lever = ({ onAnimationCompleteHandler }: LeverProps) => {
    const lottieRef = useRef<LottieRefCurrentProps>(null);

    useEffect(() => {
        if (lottieRef) {
            lottieRef.current?.animationItem?.setSpeed(2.5)
        }
    }, [lottieRef])

    const animationClickHandler = () => {
        if (lottieRef.current) {
            lottieRef.current.play();
        }
    }

    const animationCompleteHandler = () => {
        if (lottieRef.current) {
            lottieRef.current.stop();
        }
        onAnimationCompleteHandler();
    }

    return (
        <div
            className={`touch-none cursor-grab h-full ml-36`}
            onClick={animationClickHandler}
        >
            <Lottie
                lottieRef={lottieRef}
                animationData={leverAnimation}
                loop={false}
                autoplay={false}
                className='h-sm:h-[300px] h-[450px]'
                onComplete={animationCompleteHandler}
            />
        </div>
    );
};

export default Lever;