import { compactNumber } from '../common/numbers';

const Formatter = (props: { value: number; numOfDigits?: number; className?: string }) => {
  let value = props.value;
  if (isNaN(props.value) || props.value === 0) {
    value = 0;
  }

  const numOfDigits = props.numOfDigits ?? 10;

  const { strValue } = compactNumber(value, numOfDigits);

  return (
    <div className={'formAmountCont ' + props.className}>
      <span className="formAmount">{strValue}</span>
    </div>
  );
};

export default Formatter;
