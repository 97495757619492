import { useEffect, useState } from "react";

interface RollingNumberProps {
    value: number;
    duration?: number;
    decimalPlaces?: number;
    className?: string;
}

const RollingNumber: React.FC<RollingNumberProps> = ({ value,
    duration = 2,
    decimalPlaces = 2,
    className }) => {
    const [displayValue, setDisplayValue] = useState<string>("0");

    useEffect(() => {
        const spinningDigitsTimeout = setTimeout(() => {
            let currentWhole = 0;
            let currentFraction = 0;
            const wholeValue = Math.floor(value);
            const fractionValue = value - wholeValue;
            const steps = Math.ceil(duration * 60);
            const wholeIncrement = wholeValue / steps;
            const fractionIncrement = fractionValue / steps;
            const intervalTime = (duration * 1000) / steps;

            const interval = setInterval(() => {
                currentWhole += wholeIncrement;
                currentFraction += fractionIncrement;

                if (currentWhole >= wholeValue && currentFraction >= fractionValue) {
                    currentWhole = wholeValue;
                    currentFraction = fractionValue;
                    clearInterval(interval);
                }

                const combinedValue =
                    Math.floor(currentWhole) +
                    parseFloat((currentFraction).toFixed(decimalPlaces));
                setDisplayValue(combinedValue.toFixed(decimalPlaces));
            }, intervalTime);

            return () => clearInterval(interval);
        }, 800);

        return () => clearTimeout(spinningDigitsTimeout);
    }, [value, duration]);

    return (
        <p data-text={`+${displayValue}`} className={className}>+{displayValue}</p>
    );
};

export default RollingNumber;