import { useUnifiedWallet } from '@jup-ag/wallet-adapter';
import { PublicKey } from '@solana/web3.js';

function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

function useProxyWallet() {
  const state = useUnifiedWallet();

  if (getQueryVariable('pk')) {
    state.publicKey = new PublicKey(getQueryVariable('pk') as string);
    state.connected = true;
  }

  return state;
}
export default useProxyWallet;
